.footer-wrapper {
    background: var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: montserrat;
    color: var(--red);
    text-decoration: none;
}

a{
    text-decoration: none;
    color: var(--red);
}

.footer-contact-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-text{
    font-family: sugar;
}

.footer-email{
    width: 40%;
    letter-spacing: 0.1em;
}

.left-email{
    text-align:end;
}

.divider{
    width: 5%;
    text-align: center;
}

.social{
    color: var(--red);
}

.footer-social-wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media screen and (max-width: 479px) {
    .footer-logo{
        width: 30%;
        margin: 10% 0 5% 0;
    }

    .footer-contact-wrapper{
        width: 100%;
        margin-bottom: 3%;
    }

    .footer-text{
        font-size: 6vw;
        margin-bottom: 3%;
    }

    .footer-email, .divider{
        font-size: 2.5vw;
    }

    .footer-contact-text{
        font-size: 2.5vw;
    }

    .footer-social-wrapper{
        margin-bottom: 10%;
    }

    .social{
        transform: scale(2);
        margin: 0 20px 0 20px;
    }
}

@media screen and (min-width:480px) and (max-width: 768px){
    .footer-logo{
        width: 15%;
        margin: 5% 0 2.5% 0;
    }

    .footer-contact-wrapper{
        width: 100%;
        margin-bottom: 3%;
    }

    .footer-text{
        font-size: 3vw;
        margin-bottom: 1.5%;
    }

    .footer-email, .divider{
        font-size: 1.25vw;
    }

    .footer-contact-text{
        font-size: 1.25vw;
    }

    .footer-social-wrapper{
        margin-bottom: 5%;
    }

    .social{
        transform: scale(2);
        margin: 0 20px 0 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    .footer-logo{
        width: 100px;
        margin: 25px 0 10px 0;
    }

    .footer-contact-wrapper{
        width: 100%;
        margin-bottom: 15px;
    }

    .footer-text{
        font-size: 32px;
        margin-bottom: 10px;
    }

    .footer-email, .divider, .footer-contact-text{
        font-size: 12px;
    }

    .footer-social-wrapper{
        margin-bottom: 25px;
    }

    .social{
        transform: scale(2);
        margin: 0 20px 0 20px;
    }

    .divider{
        width: 30px
    }
}

@media screen and (min-width: 1025px) and (max-width: 1400px){
    .footer-logo{
        width: 100px;
        margin: 25px 0 10px 0;
    }

    .footer-contact-wrapper{
        width: 100%;
        margin-bottom: 15px;
    }

    .footer-text{
        font-size: 32px;
        margin-bottom: 10px;
    }

    .footer-email, .divider, .footer-contact-text{
        font-size: 12px;
    }

    .footer-social-wrapper{
        margin-bottom: 25px;
    }

    .social{
        transform: scale(2);
        margin: 0 20px 0 20px;
    }

    .divider{
        width: 30px
    }
}

@media screen and (min-width: 1401px){
    .footer-logo{
        width: 250px;
        margin: 25px 0 10px 0;
    }

    .footer-contact-wrapper{
        width: 100%;
        margin-bottom: 15px;
    }

    .footer-text{
        font-size: 32px;
        margin-bottom: 10px;
    }

    .footer-email, .divider, .footer-contact-text{
        font-size: 12px;
    }

    .footer-social-wrapper{
        margin-bottom: 25px;
    }

    .social{
        transform: scale(2);
        margin: 0 20px 0 20px;
    }

    .divider{
        width: 30px
    }
}