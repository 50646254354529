.header-intro{
    width: 100vw;
    background: var(--orange);
    display: flex;
    justify-content: center;
}

.header-intro-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.intro-text-wrapper{
    color: var(--white);
}

.intro-title{
    color: var(--red);
    font-family: montserrat;
    font-weight: 800;
    letter-spacing: 0.1em;
}

.intro-text-one, .intro-text-two{
    font-family: montserrat;
}

.intro-text-one{
    font-style: italic;
}

.story-wrapper{
    background: var(--white);
}

.story-block-one, .story-block-two{
    color: var(--orange);
    font-family: sugar;
}

.story-block-text{
    font-family: montserrat;
    color: var(--blue);
}

.story-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro-subtitle{
    font-family: sugar;
}

.story-block-two{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-wrapper{
    width: 100%;
    display: flex;
}


@media screen and (max-width: 479px) {
    .intro-title{
        font-size: 5vw;
    }

    .header-intro{
        flex-direction: column;
        align-items: center;
    }

    .intro-image{
        width: 50%;
        margin: 10% 0 10% 0;
    }

    .intro-text-wrapper{
        margin: 0 0 20% 0;
    }

    .intro-subtitle{
        margin: 2% 0 2% 0;
        font-size: 6vw;
    }

    .intro-text-one, .intro-text-two{
        font-size: 3vw;
        margin: 2% 0 2% 0;
    }

    .intro-text-wrapper, .story-wrapper{
        width: 80%;
    }
 
    .story-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .story-title{
        font-size: 10vw;
        margin: 5% 0 5% 0;
    }

    .story-block-text{
        font-size: 3vw;
        margin-bottom: 5%;
    }

    .story-image{
        width: 100%;
    }

    .story-image-bottom{
        width: 80%;
        margin-bottom: 20%;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    .intro-title{
        font-size: 5vw;
    }

    .header-intro{
        flex-direction: column;
        align-items: center;
    }

    .intro-image{
        width: 50%;
        margin: 10% 0 10% 0;
    }

    .intro-text-wrapper{
        margin: 0 0 20% 0;
    }

    .intro-subtitle{
        margin: 2% 0 2% 0;
        font-size: 6vw;
    }

    .intro-text-one, .intro-text-two{
        font-size: 3vw;
        margin: 2% 0 2% 0;
    }

    .intro-text-wrapper, .story-wrapper{
        width: 80%;
    }
 
    .story-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .story-title{
        font-size: 8vw;
        margin: 5% 0 5% 0;
    }

    .story-block-text{
        font-size: 2.5vw;
        margin-bottom: 5%;
    }

    .story-image{
        width: 100%;
    }

    .story-image-bottom{
        width: 80%;
        margin-bottom: 20%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    .intro-title{
        font-size: 38px;
    }

    .header-intro{
        justify-content: center;
        align-items: center;
        height: 600px;
    }

    .intro-image{
        width: 250px;
        margin: 5% 0 5% 0;
    }

    .intro-text-wrapper{
        margin-left: 50px;
    }

    .intro-subtitle{
        margin: 2% 0 2% 0;
        font-size: 28px;
    }

    .intro-text-one, .intro-text-two{
        font-size: 15px;
        margin: 10px 0 10px 0;
    }

    .intro-text-wrapper, .story-wrapper, .story-image-bottom{
        width: 450px;
        max-width: 450px;
    }

    .story-image-bottom{
        margin-bottom: 100px;
    }
 
    .story-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .story-title{
        font-size: 48px;
        margin: 100px 0 25px 0;
    }

    .story-block-text, .intro-text-one, .intro-text-two{
        font-size: 15px;
    }

    .story-block-text{
        margin-bottom: 25px;
    }

    .story-image{
        width: 100%;
    }

    .header-intro-wrapper{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 1024px){
    .header-text{
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .intro-title{
        font-size: 38px;
    }

    .header-intro{
        justify-content: center;
        align-items: center;
        height: 600px;
    }

    .intro-image{
        width: 300px;
        margin: 5% 0 5% 0;
    }

    .intro-text-wrapper{
        margin-left: 50px;
    }

    .intro-subtitle{
        margin: 2% 0 2% 0;
        font-size: 28px;
    }

    .intro-text-one, .intro-text-two{
        font-size: 15px;
        margin: 10px 0 10px 0;
    }

    .intro-text-wrapper, .story-wrapper, .story-image-bottom, .header-intro-wrapper{
        width: 800px;
        max-width: 800px;
    }

    .header-intro-wrapper{
        flex-direction: row;
    }

    .story-image-bottom{
        margin-bottom: 100px;
    }
 
    .story-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .story-title{
        font-size: 48px;
        margin: 100px 0 25px 0;
    }

    .story-block-text, .intro-text-one, .intro-text-two{
        font-size: 15px;
    }

    .story-block-text{
        margin-bottom: 25px;
    }

    .story-image{
        width: 100%;
    }
}