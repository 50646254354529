@font-face {
  font-family: montserrat;
  src: url('../../public/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: montserratThinItalic;
  src: url('../../public/Montserrat-ThinItalic.ttf');
}

@font-face {
  font-family: montserratItalic;
  src: url('../../public/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: montserratThin;
  src: url('../../public/Montserrat-Thin.ttf')
}

@font-face {
  font-family: montserratExtraLight;
  src: url('../../public/Montserrat-ExtraLight.ttf')
}

@font-face {
  font-family: sugar;
  src:url('../../public/SugarCream.otf');
}

:root{
    --teal:#BEE3DC;
    --blue:#3D4680;
    --orange:#E87C5D;
    --red:#AF1F24;
    --white:#FFFFFF;
}

body{
  margin: 0;
}

.home-wrapper{
  position: relative;
  width: 100vw;
}

a{
    text-decoration: none;
}

.home-logo{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.home-text{
  width: 70%;
  height: auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  font-family: sugar;
}

.home-lower-text{
  font-family: 'montserrat', sans-serif;
  text-align: center;
  color: var(--blue);
  font-style: bold;
  font-weight: 600;
  width: 80%;
}

.home-lower-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app{
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 380px){
  .home-background{
    width: 185%;
    height: auto;
  }

  .home-logo{
    width: 50%;
    height: auto;
    top: 100px;
  }

  .home-text{
    font-size: 8vw;
    width: 70%;
  }

  .truck-gif{
    margin: 10% 0 10% 0;
    width: 100%;
    height: auto;
  }

  .flourish{
    width: 30%;
    height: auto;
  }

  .home-lower-text{
    font-size: 3vw;
    margin: 10% 0 10% 0;
  }

  .bottom-flourish{
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 380px) and (max-width: 479px) {
  .home-background{
    width: 150%;
    height: auto;
  }

  .home-logo{
    width: 50%;
    height: auto;
    top: 100px;
  }

  .home-text{
    font-size: 8vw;
    width: 70%;
  }

  .truck-gif{
    margin: 10% 0 10% 0;
    width: 100%;
    height: auto;
  }

  .flourish{
    width: 30%;
    height: auto;
  }

  .home-lower-text{
    font-size: 3vw;
    margin: 10% 0 10% 0;
  }

  .bottom-flourish{
    margin-bottom: 10%;
  }
}

@media screen and (min-width:480px) and (max-width: 768px){
  .home-background{
    width: 150%;
    height:auto;
  }

  .home-logo{
    width: 40%;
    height: auto;
    top: 100px;
  }
  
  .home-text{
    font-size: 8vw;
    width: 70%;
  }

  .truck-gif{
    margin: 10% 0 10% 0;
    width: 100%;
    height: auto;
  }

  .flourish{
    width: 30%;
    height: auto;
  }

  .home-lower-text{
    font-size: 2.4vw;
    margin: 10% 0 10% 0;
    width: 65%;
  }

  .bottom-flourish{
    margin-bottom: 10%;
  }
}

@media screen and (min-width:769px) and (max-width: 1024px){
  .home-background{
    width: 100%;
    height:auto;
  }

  .home-logo{
    width: 40%;
    height: auto;
    top: 60px;
  }
  
  .home-text{
    font-size: 8vw;
    width: 70%;
  }

  .truck-gif{
    margin: 10% 0 10% 0;
    width: 100%;
    height: auto;
  }

  .flourish{
    width: 30%;
    height: auto;
  }

  .home-lower-text{
    font-size: 2.4vw;
    margin: 10% 0 10% 0;
    width: 65%;
  }

  .bottom-flourish{
    margin-bottom: 10%;
  }
}

@media screen and (min-width:1025px){
  .home-background{
    width: 100%;
    height:auto;
  }

  .home-logo{
    width: 30%;
    height: auto;
    top: 200px;
  }
  
  .home-text{
    font-size: 8vw;
    width: 70%;
  }

  .truck-gif{
    margin: 10% 0 10% 0;
    width: 60%;
    height: auto;
  }

  .flourish{
    width: 20%;
    height: auto;
  }

  .home-lower-text{
    font-size: 1.8vw;
    margin: 10% 0 10% 0;
    width: 50%;
  }

  .bottom-flourish{
    margin-bottom: 10%;
  }
}