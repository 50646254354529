.contact-form{
    display: flex;
    flex-direction: column;
}

.contact-row-wrapper{
    display: flex;
    justify-content: space-between;
    margin: 2% 0 2% 0;
}

.name-input, .email-input{
    width: 60%;
}

.date-input, .time-input{
    width: 28%;
}

.date-input, .time-input, .name-input, .email-input, .message-input, .button{
    border: 1px solid var(--orange);
    color: var(--blue);
    font-weight: 500;
    font-family: montserrat;
}

.button-wrapper{
    width: 100%;
}

.button{
    width: 100%;
    height: 40px;
    background: var(--orange);
    border: none;
    color: var(--white);
    margin-top: 2%;
}


@media screen and (max-width: 479px){
    .message-input{
        height: 100px;
    }

    .contact-form{
        width: 100%;
        margin-top: 20px;
    }

    .catering-wrapper{
        width: 90%;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    .message-input{
        height: 150px;
    }

    .contact-form{
        width: 80%;
        margin-top: 20px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    .message-input{
        height: 150px;
    }

    .contact-form{
        width: 80%;
        margin-top: 20px;
    }
}

@media screen and (min-width: 1025px){
    .message-input{
        height: 150px;
    }

    .contact-form{
        width: 80%;
        margin-top: 20px;
    }
}