.catering{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.partial-border {
    position: relative;
    border: 2px solid var(--orange);
    color: var(--orange);
    font-family: montserrat;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.catering-title{
    font-weight: 600;
}

.catering-text-two{
    font-style: italic;
}

.catering-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    font-family: montserrat;
    color: var(--orange);
    text-decoration: none;
}

.border-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; /* Ensures it appears on top of the border */
    background-color: white; /* Optional: a background to make it stand out */
}

.schedule-catering, .send-a-message{
    color: var(--blue);
    text-align: center;
}

.schedule-catering{
    font-family: sugar;
}

.send-a-message{
    font-family: montserrat;
}

.catering-bottom-text{
    color: var(--orange);
    font-family: montserrat;
    font-style: italic;
    margin: 20% 0 20% 0;
    text-align: center;
    font-weight: 300;
}

@media screen and (max-width: 479px){
    .partial-border{
        width: 100%;
    }

    .fork-and-spoon{
        width: 10%;
        margin: 20% 0 5% 0;
        background: var(--white);
    }

    .order{
        top: -7.5px;
    }

    .menu{
        bottom: -7.5px;
    }

    .order, .menu{
        width: 30%;
    }

    .catering-title{
        margin-top: 30px;
        font-size: 4vw;
    }

    .catering-text-two{
        margin: 30px 0 30px 0;
    }

    .schedule-catering{
        font-size: 10vw;
        margin: 100px 0 20px 0;
    }

    .send-a-message, .catering-text{
        font-size: 3vw;
        font-weight: 300;
    }

    .truck{
        width: 80%;
    }

    .catering-flourish{
        margin: 20% 0 10% 0;
        width: 50%;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    .partial-border{
        width: 90%;
    }

    .fork-and-spoon{
        width: 10%;
        margin: 20% 0 5% 0;
        background: var(--white);
    }

    .order{
        top: -7.5px;
    }

    .menu{
        bottom: -7.5px;
    }

    .order, .menu{
        width: 30%;
    }

    .catering-title{
        margin-top: 30px;
        font-size: 4vw;
    }

    .catering-text-two{
        margin: 30px 0 30px 0;
    }

    .schedule-catering{
        font-size: 10vw;
        margin: 100px 0 20px 0;
    }

    .send-a-message, .catering-text{
        font-size: 3vw;
    }

    .truck{
        width: 80%;
    }

    .catering-flourish{
        margin: 20% 0 10% 0;
        width: 50%;
    }

    .catering-wrapper{
        width: 90%;
        max-width: 600px;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    .partial-border{
        width: 90%;
    }

    .fork-and-spoon{
        width: 10%;
        margin: 20% 0 5% 0;
        background: var(--white);
    }

    .order{
        top: -7.5px;
    }

    .menu{
        bottom: -7.5px;
    }

    .order, .menu{
        width: 30%;
    }

    .catering-title{
        font-size: 3vw;
        margin-top: 30px;
    }

    .catering-text-two{
        margin: 30px 0 30px 0;
    }

    .schedule-catering{
        font-size: 10vw;
        margin: 100px 0 20px 0;
    }

    .send-a-message, .catering-text{
        font-size: 2vw;
    }

    .truck{
        width: 80%;
    }

    .catering-flourish{
        margin: 20% 0 10% 0;
        width: 50%;
    }

    .catering-wrapper{
        width: 90%;
        max-width: 600px;
    }
}

@media screen and (min-width: 769px){
    .partial-border{
        width: 90%;
    }

    .fork-and-spoon{
        width: 10%;
        margin: 20% 0 5% 0;
        background: var(--white);
    }

    .order{
        top: -7.5px;
    }

    .menu{
        bottom: -7.5px;
    }

    .order, .menu{
        width: 30%;
    }

    .catering-title{
        margin-top: 30px;
        font-size: 24px;
    }

    .catering-text-two{
        margin: 30px 0 30px 0;
    }

    .schedule-catering{
        font-size: 64px;
        margin: 100px 0 20px 0;
    }

    .send-a-message, .catering-text{
        font-size: 20px;
    }

    .truck{
        width: 80%;
    }

    .catering-flourish{
        margin: 20% 0 10% 0;
        width: 50%;
    }

    .catering-wrapper{
        width: 90%;
        max-width: 600px;
    }
}