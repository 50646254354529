.header-image{
    width: 100vw;
    height: auto;
}

.header-text{
    background: var(--teal);
    color: var(--blue);
    text-align: center;
    font-family: montserrat;
    font-weight: 700;
    padding: 20px;
    width: 100%;
}

.main-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--orange);
    font-family: montserrat;
}

.menu-item{
    display: flex;
}

.menu-filler{
    height: 20%;
}

.menu-name{
    font-weight: 700;
}


@media screen and (max-width: 479px) {
    .header-text{
        font-size: 2.5vw;
    }

    .main-menu{
        width: 100%;
    }

    .menu-filler{
        width: 10%;
        margin-right: 5%;
        margin-left: 10%;
    }

    .menu-banner{
        width: auto;
        height: 27px;
        margin: 10% 0 5% 25%;
    }

    .menu-name{
        font-size: 3.4vw;
        margin-bottom: 2%;
    }

    .menu-description{
        font-size: 2.5vw;
        line-height: 1.5;
    }

    .menu-entry-wrapper{
        margin-bottom: 10%;
    }

    .menu-flourish{
        margin: 10% 0 10% 0;
        height: 27px;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    .header-text{
        font-size: 2vw;
    }

    .menu-filler{
        width: 10%;
        margin-right: 5%;
        margin-left: 10%;
    }

    .menu-banner{
        width: auto;
        height: 27px;
        margin: 10% 0 5% 25%;
    }

    .menu-name{
        font-size: 2.5vw;
        margin-bottom: 2%;
    }

    .menu-description{
        font-size: 2vw;
        line-height: 1.5;
    }

    .menu-entry-wrapper{
        margin-bottom: 10%;
    }

    .menu-flourish{
        margin: 10% 0 10% 0;
        height: 27px;
    }
}

@media screen and (min-width: 769px){
    .header-text{
        font-size: 20px;
    }

    .menu-filler{
        width: 40px;
        margin-right: 15px;
        margin-left: 50px;
    }

    .menu-banner{
        width: auto;
        height: 40px;
        margin: 50px 0 5% 125px;
    }

    .menu-name{
        font-size: 24px;
        margin-bottom: 2%;
    }

    .menu-description{
        font-size: 16px;
        line-height: 1.5;
    }

    .menu-entry-wrapper{
        margin-bottom: 40px;
    }

    .menu-flourish{
        margin: 10% 0 10% 0;
        height: 50px;
    }
}
/* 
@media screen and (min-width: 1025px) and (max-width: 1400px){
    .header-text{
        font-size: 2vw;
    }

    .menu-item{
        width: 50%;
    }

    .menu-filler{
        width: 10%;
        margin-right: 5%;
        margin-left: 10%;
    }

    .menu-banner{
        width: auto;
        height: 27px;
        margin: 10% 0 5% 25%;
    }

    .menu-name{
        font-size: 2.5vw;
        margin-bottom: 2%;
    }

    .menu-description{
        font-size: 2vw;
        line-height: 1.5;
    }

    .menu-entry-wrapper{
        margin-bottom: 10%;
    }

    .menu-flourish{
        margin: 10% 0 10% 0;
        height: 27px;
    }
}
 */
