:root{
    --teal:#BEE3DC;
    --blue:#3D4680;
    --orange:#E87C5D;
    --red:#AF1F24;
    --white:#FFFFFF;
    --header-height: 80px;
}

.header-wrapper{
    display: flex;
    height: var(--header-height);
    width: 100%;
    position: fixed;
    z-index: 10;
    background: var(--orange);
    box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.05);
}

.app{
    padding-top: var(--header-height);
    width: 100vw;
}

.header-left, .header-mid, .header-right{
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-link {
    color: var(--white);
    font-family: 'montserrat', sans-serif;
    font-size: .8rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .7s ease;
    width: 33.3333%;
}

.header-link:hover {
    background: var(--red);
    transition: background-color .7s ease;
}

.active-link {
    background: var(--red);
}

@media screen and (max-width: 479px) {
    :root{
        --header-height: 80px;
    }
    .header-wrapper{
        height: var(--header-height);
    }

    .header-mid{
        width: 0%;
    }

    .header-left{
        width: 30%;
    }

    .header-right{
        width: 75%;
    }

    .header-logo{
        width: 90%;
    }
}

@media screen and (min-width: 480px) and (max-width: 768px){
    :root{
        --header-height: 80px;
    }

    .header-wrapper{
        height: var(--header-height);
    }

    .header-left{
        width: 35%;
    }

    .header-right{
        width: 75%;
    }

    .header-logo{
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    :root{
        --header-height: 80px;
    }

    .header-wrapper{
        height: var(--header-height);
    }

    .header-mid{
        width: 70%;
    }

    .header-right{
        width: 500px;
    }

    .header-left{
        width: 300px;
    }

    .header-logo{
        width: 150px;
    }
}

@media screen and (min-width: 1025px){
    :root{
        --header-height: 80px;
    }

    .header-wrapper{
        height: var(--header-height);
        justify-content: space-between;
    }

    .header-mid{
        width: 70%;
    }

    .header-right{
        width: 500px;
    }

    .header-logo{
        width: 200px;
    }
}